define("bottomline-execution/components/site-load-data", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "@ember/service", "bottomline-execution/mixins/storages-loader"], function (_exports, _computed, _component, _object, _utils, _service, _storagesLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_storagesLoader.default, {
    store: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    selectedTripStop: null,
    tripStopStorages: (0, _computed.alias)('selectedTripStop.trip_stop_storages'),
    allSiteStorages: (0, _computed.mapBy)('tripStopStorages', 'storage'),
    areDetailsShown: false,
    isAtgInfoLoading: false,
    sortedTripStopStorages: (0, _object.computed)('tripStopStorages', function () {
      var tripStopStorages = this.get('tripStopStorages');
      return (0, _utils.isPresent)(tripStopStorages) ? tripStopStorages.sortBy('storage.SequenceNb') : null;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('internetConnection.isGoodQuality')) {
        this.updateAtgInformation();
      }
    },
    updateAtgInformation: function updateAtgInformation() {
      var _this = this;

      this.set('isAtgInfoLoading', true);

      this._loadRecentInventories(this.allSiteStorages).finally(function () {
        _this.set('isAtgInfoLoading', false);
      });
    },
    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('areDetailsShown');
      }
    }
  });

  _exports.default = _default;
});