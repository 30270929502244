define("bottomline-execution/services/bx/offline-syncer", ["exports", "bottomline-execution/services/offline-syncer", "@ember/application", "@ember/service", "@ember/utils", "rsvp", "@sentry/browser"], function (_exports, _offlineSyncer, _application, _service, _utils, _rsvp, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _offlineSyncer.default.extend({
    f7: (0, _service.inject)('framework7'),
    internetConnection: (0, _service.inject)(),
    store: (0, _service.inject)(),
    init: function init() {
      this._super.apply(this, arguments);

      if (window.cordova) {
        this._installTabletEventListener();
      } else {
        this._installBrowserEventListener();
      }
    },
    _installTabletEventListener: function _installTabletEventListener() {
      var _this = this;

      document.addEventListener("online", function () {
        _this._synchronize();
      }, false);
    },
    _installBrowserEventListener: function _installBrowserEventListener() {
      var _this2 = this;

      if ((0, _utils.isPresent)(navigator.connection)) {
        navigator.connection.addEventListener('change', function () {
          if (navigator.onLine) _this2._synchronize();
        });
      }
    },
    _synchronize: function _synchronize() {
      var _this3 = this;

      var executionEvents = this._executionEvents();

      if ((0, _utils.isEmpty)(executionEvents)) return;
      this.get('f7').alert('Start to synchronize data', 'Device is online again', function () {
        _this3.get('f7').showPreloader();

        _this3._sendEventsToBackend(executionEvents).catch(function (error) {
          if (!_this3.get('internetConnection.offline')) {
            // Something else than the internet connection broke the synchronization
            Sentry.captureException(error);
          }
        }).finally(function () {
          return _this3.get('f7').hidePreloader();
        });
      });
    },
    _executionEvents: function _executionEvents() {
      return this.get('store').peekAll('execution-event').sortBy('id');
    },
    _sendEventsToBackend: function _sendEventsToBackend(executionEvents) {
      var _this4 = this;

      return executionEvents.reduce(function (promiseChain, event) {
        return promiseChain.then(function (_) {
          return _this4._send(event);
        });
      }, _rsvp.Promise.resolve());
    },
    _send: function _send(event) {
      var snapshot = event.get('snapshot');
      return this._adapterFor(snapshot).updateRecord(this.get('store'), snapshot.type, snapshot).then(function (_) {
        return event.deleteRecord();
      });
    },
    _adapterFor: function _adapterFor(snapshot) {
      return (0, _application.getOwner)(this).lookup("adapter:".concat(snapshot.modelName));
    }
  });

  _exports.default = _default;
});