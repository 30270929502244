define("bottomline-execution/resolver", ["exports", "ember-resolver", "bottomline-execution/mixins/raw-app-state", "bottomline-execution/mixins/raw-truck-settings"], function (_exports, _emberResolver, _rawAppState, _rawTruckSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberResolver.default.extend(_rawAppState.default, _rawTruckSettings.default, {
    resolve: function resolve(fullName) {
      var parsedName = this.parseName(fullName);

      switch (parsedName.type) {
        case 'model':
          fullName = this._modelNameFor(parsedName.name);
          break;

        case 'serializer':
          fullName = this._serializerFor(parsedName.name);
          break;

        case 'adapter':
          fullName = this._adapterFor(parsedName.name);
          break;

        case 'service':
          fullName = this._serviceFor(parsedName.name);
          break;
      }

      fullName = fullName || "".concat(parsedName.type, ":").concat(parsedName.name);
      return this._super(fullName);
    },
    _beAdapters: {
      noOperation: ['truck-inventory'],
      specialized: ['inventory', 'pdf-document', 'picture']
    },
    _bxAdapters: {
      noOperation: ['adr-material', 'extra-time', 'inventory-correction', 'material-seller', 'otc-material', 'trailer', 'trip-stop-storage', 'trip-stop-truck-storage', 'truck-inventory'],
      specialized: ['end-inventory', 'inventory', 'material', 'picture', 'pdf-document', 'shift', 'storage', 'trip-stop', 'trip', 'truck']
    },
    _sharedAdapters: ['state', 'truck-setting'],
    _bxSpecificModels: ['customer', 'end-inventory', 'inventory-correction', 'picture', 'site', 'trip-stop-storage', 'trip-stop-truck-storage', 'trip-stop', 'trip'],
    _beSpecificModels: ['customer', 'trip-stop'],
    _backendSpecificSerializers: ['end-inventory', 'material', 'picture', 'inventory', 'pdf-document', 'shift', 'site', 'site-remark', 'storage', 'trip', 'trip-stop', 'trip-stop-storage', 'trip-stop-truck-storage', 'truck', 'truck-inventory', 'truck-storage'],
    _adapterFor: function _adapterFor(adapterName) {
      if (this._sharedAdapters.includes(adapterName)) return "adapter:".concat(adapterName);

      if (this.get('useBxBackend')) {
        return this._bxAdapterFor(adapterName);
      } else {
        return this._beAdapterFor(adapterName);
      }
    },
    _bxAdapterFor: function _bxAdapterFor(adapterName) {
      if (this._bxAdapters.noOperation.includes(adapterName)) return 'adapter:no-op';
      if (this._bxAdapters.specialized.includes(adapterName)) return "adapter:bx/".concat(adapterName);
      return "adapter:bx/base-adapter";
    },
    _beAdapterFor: function _beAdapterFor(adapterName) {
      if (this._beAdapters.noOperation.includes(adapterName)) return 'adapter:no-op';
      if (this._beAdapters.specialized.includes(adapterName)) return "adapter:be/".concat(adapterName);
      return "adapter:be/base-adapter";
    },
    _modelNameFor: function _modelNameFor(modelName) {
      if (this.get('useBxBackend')) {
        if (this._bxSpecificModels.includes(modelName)) return "model:bx/".concat(modelName);
      } else {
        if (this._beSpecificModels.includes(modelName)) return "model:be/".concat(modelName);
      }
    },
    _serializerFor: function _serializerFor(serializerName) {
      if (this._backendSpecificSerializers.includes(serializerName)) {
        if (this.get('useBxBackend')) return "serializer:bx/".concat(serializerName);
      }
    },
    _serviceFor: function _serviceFor(serviceName) {
      if (serviceName == 'current-truck-inventory') {
        if (this.get('useCompartments')) return 'service:compartment-based/current-truck-inventory';
        return 'service:material-based/current-truck-inventory';
      }

      if (serviceName == 'offline-syncer') {
        if (this.get('useBxBackend')) return 'service:bx/offline-syncer';
        return 'service:offline-syncer';
      }
    }
  });

  _exports.default = _default;
});