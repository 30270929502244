define("bottomline-execution/controllers/trip-stops/show/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "ember-data", "bottomline-execution/mixins/picture-browser", "bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages", "bottomline-execution/config/environment", "bottomline-execution/utils/guid-util", "bottomline-execution/utils/delivery-ticket-nb"], function (_exports, _computed, _controller, _object, _utils, _service, _emberData, _pictureBrowser, _plannedOrConfirmedTripStopStorages, _environment, _guidUtil, _deliveryTicketNb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_pictureBrowser.default, _plannedOrConfirmedTripStopStorages.default, {
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    androidIntentsAtx: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    i18n: (0, _service.inject)(),
    remoteLogging: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    locationService: (0, _service.inject)('location'),
    vehicleStorages: (0, _service.inject)(),
    meterCoupling: (0, _service.inject)(),
    meterDeliveriesData: [],
    isMeterDeliveriesDataPresent: (0, _object.computed)('meterDeliveriesData', function () {
      return (0, _utils.isPresent)(this.get('meterDeliveriesData'));
    }),
    isProcessingDeliveries: (0, _computed.alias)('meterCoupling.isProcessingDeliveries'),
    canNotChangeDeliveries: (0, _computed.alias)('meterCoupling.isBusy'),
    locationConfirmedId: null,
    showAtgInformation: (0, _computed.alias)('currentTruckSettings.show_atg_information'),
    showPredip: (0, _computed.bool)('currentTruckSettings.predip_enabled'),
    editablePredip: (0, _computed.equal)('currentTruckSettings.predip_enabled', 'on_unload'),
    editableUllage: (0, _computed.equal)('currentTruckSettings.predip_enabled', 'on_unload_by_ullage'),
    postdipCalculatedByUllage: (0, _object.computed)('currentTruckSettings.postdip_enabled', function () {
      return ['optional_by_ullage', 'required_by_ullage'].includes(this.get('currentTruckSettings.postdip_enabled'));
    }),
    showPostdip: (0, _object.computed)('currentTruckSettings.postdip_enabled', function () {
      return ['optional', 'required'].includes(this.get('currentTruckSettings.postdip_enabled'));
    }),
    showDischargePlanButton: (0, _computed.alias)('currentTruckSettings.dischargeplan_on_unload'),
    isOpeningDischargePlan: false,
    filterLoadTruckPlannedCompartments: false,
    filterLoadTrailerPlannedCompartments: false,
    resetLoadCompartmentFilters: function resetLoadCompartmentFilters() {
      this.set('filterLoadTruckPlannedCompartments', false);
      this.set('filterLoadTrailerPlannedCompartments', false);
    },
    filterUnloadTruckPlannedCompartments: true,
    filterUnloadTrailerPlannedCompartments: true,
    resetUnloadCompartmentFilters: function resetUnloadCompartmentFilters() {
      this.set('filterUnloadTruckPlannedCompartments', true);
      this.set('filterUnloadTrailerPlannedCompartments', true);
    },
    showTripStopStorages: (0, _computed.alias)('appConfiguration.truckSettings.show_planned_materials'),
    showUnplannedStorages: false,
    showUnplannedTripStopStorages: false,
    offline: (0, _computed.alias)('internetConnection.offline'),
    showInventory: true,
    currentTripStop: (0, _computed.alias)('state.tripStop'),
    showLoad15LitersSwitch: (0, _object.computed)('currentTruckSettings.temp_density_ltr15_load_enabled', function () {
      return this.get('currentTruckSettings.temp_density_ltr15_load_enabled') !== false;
    }),
    showDelivery15LitersSwitch: (0, _object.computed)('currentTruckSettings.temp_density_ltr15_delivery_enabled', function () {
      return this.get('currentTruckSettings.temp_density_ltr15_delivery_enabled') !== false;
    }),
    showTemperatureDensity15Liters: (0, _computed.alias)('model.showTemperatureDensity15Liters'),
    showTruckCompartments: (0, _object.computed)(function () {
      return (0, _utils.isPresent)(this.get('model.truckInventories'));
    }),
    showTrailerCompartments: (0, _object.computed)(function () {
      return (0, _utils.isPresent)(this.get('model.trailerInventories'));
    }),
    currentTruck: (0, _computed.alias)('state.currentTruck'),
    currentTruckSettings: (0, _computed.alias)('appConfiguration.truckSettings'),
    showAllLoadTruckCompartments: (0, _object.computed)('filterLoadTruckPlannedCompartments', 'loadTripStopStorage', function () {
      return !this.get('filterLoadTruckPlannedCompartments') || this.get('loadTripStopStorage.plannedQty') < 1;
    }),
    showAllLoadTrailerCompartments: (0, _object.computed)('filterLoadTrailerPlannedCompartments', 'loadTripStopStorage', function () {
      return !this.get('filterLoadTrailerPlannedCompartments') || this.get('loadTripStopStorage.plannedQty') < 1;
    }),
    showAllUnloadTruckCompartments: (0, _object.computed)('filterUnloadTruckPlannedCompartments', 'unloadTripStopStorage', function () {
      return !this.get('filterUnloadTruckPlannedCompartments') || this.get('unloadTripStopStorage.plannedQty') < 1;
    }),
    showAllUnloadTrailerCompartments: (0, _object.computed)('filterUnloadTrailerPlannedCompartments', 'unloadTripStopStorage', function () {
      return !this.get('filterUnloadTrailerPlannedCompartments') || this.get('unloadTripStopStorage.plannedQty') < 1;
    }),
    isAtx: (0, _object.computed)(function () {
      return _environment.default.APP.target === 'atx';
    }),
    visibleTripStopStorages: (0, _object.computed)('model.trip_stop_storages.@each.storage', 'showUnplannedStorages', function () {
      var showUnplannedStorages = this.get('showUnplannedStorages');
      return this.plannedOrConfirmedTripStopStorages({
        tripStop: this.get('model'),
        tripStopStorages: this.get('model.trip_stop_storages'),
        includeUnplannedStorages: showUnplannedStorages,
        storageGroupID: this.get('model.StorageGroupID')
      }).sortBy('storage.SequenceNb');
    }),
    hasRecentInventory: (0, _object.computed)('visibleTripStopStorages', function () {
      return this.get('visibleTripStopStorages').any(function (tts) {
        return tts.get('storage.recent_inventory');
      });
    }),
    plannedTripStopStorages: (0, _computed.alias)('model.plannedTripStopStorages'),
    currentTruckId: (0, _computed.alias)('state.currentTruck'),
    showQuestions: (0, _computed.alias)('state.questionaire'),
    state: (0, _object.computed)(function () {
      return this.store.peekRecord('state', 1);
    }),
    showDeliveryTicketInput: (0, _object.computed)('appConfiguration.truckSettings.delivery_ticketnb_per_tank', 'appConfiguration.truckSettings.hide_delivery_ticket_input', function () {
      return !this.get('appConfiguration.truckSettings.delivery_ticketnb_per_tank') && !this.get('appConfiguration.truckSettings.hide_delivery_ticket_input');
    }),
    enableSiteLocationUpdate: (0, _object.computed)('locationService.lat', 'appConfiguration.truckSettings.register_site_coordinates', function () {
      return (0, _utils.isPresent)(this.get('locationService.lat')) && this.get('appConfiguration.truckSettings.register_site_coordinates');
    }),
    enableTankLocationUpdate: (0, _object.computed)('locationService.lat', 'appConfiguration.truckSettings.register_tank_coordinates', function () {
      return (0, _utils.isPresent)(this.get('locationService.lat')) && this.get('appConfiguration.truckSettings.register_tank_coordinates');
    }),
    inventories: (0, _object.computed)('model.isUnload', 'showUnplannedStorages', 'appConfiguration.truckSettings.dip_unplanned_storages', function () {
      var _this = this;

      if (this.get('model.isUnload') && this.get('showUnplannedStorages') && this.get('appConfiguration.truckSettings.dip_unplanned_storages')) {
        var storageGroupId = this.get('model.StorageGroupID');
        var promise = this.get('model.site.storages').then(function (siteStorages) {
          return siteStorages.filter(function (storage) {
            return storage.get('StorageGroupID') != storageGroupId;
          }).reduce(function (inventories, storage) {
            var newInventory = _this.get('store').createRecord('inventory', {
              id: (0, _guidUtil.guidUtil)(),
              storage: storage,
              StorageID: storage.get('id'),
              InventoryDT: moment(),
              inventoryQty: 0,
              tripStopId: _this.get('model.id')
            });

            if (newInventory.get('canBeUpdated')) {
              storage.set('recent_inventory', newInventory);
              inventories.push(newInventory);
            }

            return inventories;
          }, []);
        });
        return _emberData.default.PromiseArray.create({
          promise: promise
        });
      } else {
        return [];
      }
    }),
    canRunDeliveriesProcessing: (0, _object.computed)('visibleTripStopStorages.@each.processDelivery', 'meterCoupling.isAvailable', function () {
      var deliveriesToProcess = this.get('visibleTripStopStorages').filter(function (storage) {
        return storage.get('processDelivery');
      });
      return (0, _utils.isPresent)(deliveriesToProcess) && this.get('meterCoupling.isAvailable');
    }),
    visibleTripStopStoragesMaterials: (0, _object.computed)('visibleTripStopStorages.@each.material', function () {
      return this.get('visibleTripStopStorages').mapBy('material');
    }),
    loadAsPlanned: function loadAsPlanned(truckInventory) {
      var tripStopTruckStorage = this.get('model.trip_stop_truck_storages').find(function (tsts) {
        return tsts.get('TruckStorageID') === truckInventory.get('truck_storage_id');
      });

      if (tripStopTruckStorage && tripStopTruckStorage.get('hasPlannedMaterial') && (0, _utils.isEmpty)(tripStopTruckStorage.get('confirmedQty'))) {
        tripStopTruckStorage.setConfirmedFromPlannedQty();
      }
    },
    _confirmTripStop: function _confirmTripStop() {
      var _this2 = this;

      var model = this.get('model');
      console.log('Confirming trip stop');
      this.get('f7').showPreloader();
      model.confirm().then(function () {
        console.log('Trip stop confirmed');
        console.log('Preparing transition');

        if (_environment.default.APP.target === 'atx') {
          _this2.get('androidIntentsAtx').stop(model.get('ActivityCode'));
        }

        if (model.get('isUnload')) {
          _this2.set('showUnplannedStorages', false);
        }

        console.log('Execute transition');

        _this2.get('f7').hidePreloader();

        var truckSettings = _this2.get('currentTruckSettings');

        if (model.get('isUnload') && truckSettings.get('authorize_trip_stop')) {
          _this2.transitionToRoute('trip-stops.show.authorize-delivery', model.get('TripID'), model.id);
        } else {
          _this2.transitionToRoute('trips');
        }

        return false;
      });
    },
    _prepareAndValidateForMeter: function _prepareAndValidateForMeter(storagesToDeliver) {
      var plannedDeliveries = storagesToDeliver.map(function (storage) {
        return {
          productId: String(storage.get('material.meter_material_number')),
          volume: String(storage.get('plannedQty'))
        };
      });

      if (plannedDeliveries.mapBy('productId').every(function (productId) {
        return (0, _utils.isPresent)(productId);
      })) {
        return plannedDeliveries;
      } else {
        this.get('f7').alert(this.get('i18n').t('trip-stop.meter_material_number.unavailable'), '');
        return [];
      }
    },
    _assignMeterDeliveriesData: function _assignMeterDeliveriesData(meterDeliveriesData, storagesToDeliver) {
      var _this3 = this;

      var total = function total(value) {
        return value == 'null' ? 0 : value;
      };

      this.set('meterDeliveriesData', meterDeliveriesData);
      meterDeliveriesData.forEach(function (deliveryData, index) {
        if (deliveryData.volume) {
          var meterDeliveryInfo = "MeterId=".concat(deliveryData.meterId, ";                                   CounterStart=").concat(total(deliveryData.totalVolumeStart), ";                                   CounterEnd=").concat(total(deliveryData.totalVolumeEnd), ";                                   Counter15Start=").concat(total(deliveryData.totalVolume15cStart), ";                                   Counter15End=").concat(total(deliveryData.totalVolume15cEnd)).replace(/\s/g, "");
          var deliveryStoragAttributes = {
            confirmedQty: deliveryData.volume,
            meterQty: deliveryData.volume,
            TicketNb: (0, _deliveryTicketNb.deliveryTicketNb)(_this3.get('appState.currentTruck'), deliveryData.meterIndex, deliveryData.ticketNb),
            MeterDeliveryInfo: meterDeliveryInfo
          };
          if (deliveryData.volume15c !== 'null') deliveryStoragAttributes.confirmed15Qty = deliveryData.volume15c;
          if (deliveryData.temperature !== 'null') deliveryStoragAttributes.Temperature = deliveryData.temperature;
          storagesToDeliver.objectAt(index).setProperties(deliveryStoragAttributes);
        }
      });
    },
    actions: {
      toggleShowTripStopStorages: function toggleShowTripStopStorages() {
        this.toggleProperty('showTripStopStorages');

        if (!this.get('showTripStopStorages')) {
          this.set('loadTripStopStorage', null);
          this.resetLoadCompartmentFilters();
          this.set('showUnplannedTripStopStorages', false);
        }

        return false;
      },
      toggleShowUnplannedTripStopStorages: function toggleShowUnplannedTripStopStorages() {
        this.toggleProperty('showUnplannedTripStopStorages');

        if (this.get('showUnplannedTripStopStorages')) {
          this.set('showTripStopStorages', true);
        }
      },
      toggleFilterLoadTruckPlannedCompartments: function toggleFilterLoadTruckPlannedCompartments() {
        this.toggleProperty('filterLoadTruckPlannedCompartments');
      },
      toggleFilterLoadTrailerPlannedCompartments: function toggleFilterLoadTrailerPlannedCompartments() {
        this.toggleProperty('filterLoadTrailerPlannedCompartments');
      },
      toggleFilterUnloadTruckPlannedCompartments: function toggleFilterUnloadTruckPlannedCompartments() {
        this.toggleProperty('filterUnloadTruckPlannedCompartments');
      },
      toggleFilterUnloadTrailerPlannedCompartments: function toggleFilterUnloadTrailerPlannedCompartments() {
        this.toggleProperty('filterUnloadTrailerPlannedCompartments');
      },
      toggleUnplannedStorages: function toggleUnplannedStorages() {
        if (this.get('isProcessingDeliveries')) {
          return false;
        }

        this.toggleProperty('showUnplannedStorages');
      },
      toggleDeliveryToProcess: function toggleDeliveryToProcess(tripStopStorageId) {
        var tripStopStorage = this.get('visibleTripStopStorages').find(function (storage) {
          return storage.get('id') == tripStopStorageId;
        });

        if ((0, _utils.isPresent)(tripStopStorage)) {
          tripStopStorage.toggleProperty('processDeliveryFlag');
        }
      },
      openDischargePlan: function openDischargePlan() {
        var _this4 = this;

        var tripStopId = this.get('model.id');

        var flagOpeningDischargePlan = function flagOpeningDischargePlan(opening) {
          _this4.set('isOpeningDischargePlan', opening);
        };

        var errorOpeningDocument = function errorOpeningDocument(_error) {
          _this4.get('f7').alert(_this4.get('i18n').t('trip-stop.dischargePlan.unavailable'), '');
        };

        var pdfDocument = this.get('store').createRecord('pdf-document', {
          documentType: 'dischargePlan',
          modelId: tripStopId
        });
        pdfDocument.open({
          signalOpening: flagOpeningDischargePlan,
          showWarning: errorOpeningDocument
        });
      },
      processDeliveries: function processDeliveries() {
        var _this5 = this;

        var storagesToDeliver = this.get('visibleTripStopStorages').filterBy('processDelivery');

        var deliveriesData = this._prepareAndValidateForMeter(storagesToDeliver);

        if ((0, _utils.isEmpty)(deliveriesData)) return false;
        this.get('meterCoupling').connectAndProcessDeliveries(this.get('model'), deliveriesData).then(function (meterDeliveriesData) {
          return _this5._assignMeterDeliveriesData(meterDeliveriesData, storagesToDeliver);
        }).catch(function (error) {
          _this5.get('remoteLogging').log("Error processing deliveries: ".concat(error.message), 'ERROR');

          _this5.get('toast').error(_this5.get('i18n').t(error.i18nKey));
        });
        return false;
      },
      stopProcessDeliveries: function stopProcessDeliveries() {
        var _this6 = this;

        this.get('meterCoupling').cancelDeliveries().then(function (completedDeliveries) {
          var storagesToDeliver = _this6.get('visibleTripStopStorages').filterBy('processDelivery');

          _this6._assignMeterDeliveriesData(completedDeliveries, storagesToDeliver);
        }).catch(function (error) {
          _this6.get('remoteLogging').log("Error cancelling deliveries: ".concat(error.message), 'ERROR');

          _this6.get('toast').error(_this6.get('i18n').t(error.i18nKey));
        });
        return false;
      },
      confirm: function confirm() {
        var _this7 = this;

        var postDipsInvalidMessage = this.get('model.validations.warnings').filterBy('attribute', 'PostdipsOnUnloadValid').mapBy('message').join(',');

        if ((0, _utils.isPresent)(postDipsInvalidMessage)) {
          var formattedMessage = postDipsInvalidMessage.split(',').join('<p>');
          this.get('f7').modal({
            title: this.get('i18n').t('postdip_validation.title'),
            text: this.get('i18n').t('postdip_validation.warning') + '<p>' + formattedMessage,
            verticalButtons: true,
            buttons: [{
              text: this.get('i18n').t('postdip_validation.cancel')
            }, {
              text: this.get('i18n').t('postdip_validation.confirm'),
              bold: true,
              onClick: function onClick() {
                _this7._confirmTripStop();
              }
            }]
          });
        } else {
          this._confirmTripStop();
        }
      },
      cancel: function cancel() {
        this.get('model').cancel();

        if (_environment.default.APP.target === 'atx') {
          this.get('androidIntentsAtx').stop(this.get('model.ActivityCode'));
        }

        this.transitionToRoute('trips');
      },
      selectLoadTripStopStorage: function selectLoadTripStopStorage(id) {
        this.set('loadTripStopStorage', this.store.peekRecord('trip-stop-storage', id));
      },
      selectUnloadTripStopStorage: function selectUnloadTripStopStorage(id) {
        this.set('unloadTripStopStorage', this.store.peekRecord('trip-stop-storage', id));
        this.resetUnloadCompartmentFilters();
      },
      truckIsEmpty: function truckIsEmpty() {
        this.get('model.trip_stop_truck_storages').filter(function (tsts) {
          return tsts.get('confirmedQty') !== 0;
        }).setEach('confirmedQty', 0);
        this.get('model.trip_stop_storages').filter(function (tsts) {
          return tsts.get('confirmedQty') !== 0;
        }).setEach('confirmedQty', 0);
        this.get('model.truckInventories').setEach('currentQty', 0);
        this.get('model.trailerInventories').setEach('currentQty', 0);
        return false;
      },
      getLoads: function getLoads() {
        var loadsImported = 0;

        if (this.get('model.trip_stop_storages').invoke('getLoad')) {
          loadsImported = loadsImported + 1;
        }

        if (loadsImported === 0) {
          alert('Load information not available');
        }
      },
      loadAsPlanned: function loadAsPlanned() {
        var _this8 = this;

        this.get('model.truckInventories').forEach(function (inventory) {
          _this8.loadAsPlanned(inventory);
        });
        this.get('model.trailerInventories').forEach(function (inventory) {
          _this8.loadAsPlanned(inventory);
        });
        this.get('model.trip_stop_storages').invoke('updateConfirmedQty');
      },
      scanByTank: function scanByTank(tank) {
        alert(tank);
      },
      saveStorageCurrentLocation: function saveStorageCurrentLocation(storage) {
        var _this$get$getProperti = this.get('locationService').getProperties('lat', 'lng'),
            latitude = _this$get$getProperti.lat,
            longitude = _this$get$getProperti.lng;

        storage.setProperties({
          latitude: latitude,
          longitude: longitude
        });
        storage.save();
      },
      saveStorageNewlocation: function saveStorageNewlocation(storage, latitude, longitude) {
        storage.setProperties({
          latitude: latitude,
          longitude: longitude
        });
        return storage.save();
      },
      saveSiteNewlocation: function saveSiteNewlocation(tripStop, latitude, longitude) {
        var site = tripStop.get('site');
        site.setProperties({
          latitude: latitude,
          longitude: longitude
        });
        tripStop.setProperties({
          latitude: latitude,
          longitude: longitude
        });
        return site.save();
      },
      showLocationConfirmer: function showLocationConfirmer(id) {
        var _this9 = this;

        this.set('locationConfirmedId', null);
        setTimeout(function () {
          _this9.set('locationConfirmedId', id);
        }, 500);
      },
      cancelUpdateLocation: function cancelUpdateLocation() {
        this.set('locationConfirmedId', null);
      }
    }
  });

  _exports.default = _default;
});