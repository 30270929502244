define("bottomline-execution/adapters/be/base-adapter", ["exports", "ember-simple-auth/mixins/data-adapter-mixin", "active-model-adapter", "@ember/object", "@ember/service", "bottomline-execution/config/environment"], function (_exports, _dataAdapterMixin, _activeModelAdapter, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var BEAdapter = _activeModelAdapter.ActiveModelAdapter.extend(_dataAdapterMixin.default, {
    namespace: _environment.default.api,
    authorizer: 'authorizer:devise',
    requestHeaders: (0, _service.inject)(),
    host: (0, _object.computed)(function () {
      var domain = localStorage.state ? JSON.parse(localStorage.state).state.records[1].domain : null;
      return domain;
    }).volatile(),
    headers: (0, _object.computed)('session.isAuthenticated', function () {
      var authorize = this.get('session.isAuthenticated');
      return this.get('requestHeaders').defaultHeaders({
        authorize: authorize
      });
    }),
    ajaxOptions: function ajaxOptions(url, type, options) {
      var hash = this._super(url, type, options);

      hash.timeout = 200000;
      return hash;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 403) {
        if (localStorage['ember_simple_auth:session']) {
          localStorage.setItem('ember_simple_auth:session', null);
        }

        if (window.cordova) {
          document.location = 'index.html';
        } else {
          document.location = '/';
        }
      }

      return this._super(status, headers, payload);
    }
  });

  var _default = BEAdapter;
  _exports.default = _default;
});