define("bottomline-execution/controllers/login", ["exports", "@ember/object/computed", "@ember/error", "@ember/object", "@ember/controller", "@ember/service", "fetch", "bottomline-execution/mixins/authenticate"], function (_exports, _computed, _error, _object, _controller, _service, _fetch, _authenticate2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _controller.default.extend(_authenticate2.default, {
    i18n: (0, _service.inject)(),
    session: (0, _service.inject)(),
    truckSettingsAjax: (0, _service.inject)(),
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    truckSelector: (0, _service.inject)(),
    bxBackend: (0, _computed.equal)('appState.backend_type', 'bx'),
    authenticator: (0, _object.computed)('bxBackend', function () {
      if (this.get('bxBackend')) return 'auth0';
      return 'devise';
    }),
    toast: (0, _service.inject)(),
    _authenticate: function _authenticate(userName, password) {
      var _this = this;

      var authenticator = "authenticator:".concat(this.get('authenticator'));
      this.get('session').authenticate(authenticator, userName, password).then(function () {
        _this.get('truckSelector').resetCurrentTruck();
      }, function (error) {
        var errorMessage = 'unexpected_authentication_error';

        if (error instanceof TypeError) {
          switch (error.message) {
            case 'Network request failed':
              errorMessage = 'domain_unreachable';
              break;
          }
        } else if (_this._isErrorInResponse(error)) {
          error = _this._httpResponseError(error);

          switch (error.status) {
            case 401:
            case 403:
              errorMessage = 'login_failed';
              break;

            case 404:
              errorMessage = 'domain_not_set';
              break;

            default:
              errorMessage = "".concat(error.status, " ").concat(error.statusText);
          }
        }

        _this.set('errorMessage', _this.get('i18n').t(errorMessage));
      }).catch(function (error) {
        // display an error when the authenticator raises an error
        _this.set('errorMessage', _this.get('i18n').t(error));
      });
    },
    _isErrorInResponse: function _isErrorInResponse(error) {
      return error instanceof _fetch.Response || error instanceof _error.default;
    },
    _httpResponseError: function _httpResponseError(error) {
      if (error instanceof _fetch.Response) return error;

      if (error instanceof _error.default) {
        return {
          'status': error.payload.status,
          'statusText': error.payload.error
        };
      }
    },
    actions: {
      updateLanguage: function updateLanguage(value) {
        var state = this.get('model');
        state.set('language', value);
        this.set('i18n.locale', value);
        state.save();
      },
      authenticate: function authenticate() {
        var _this$getProperties = this.getProperties('identification', 'password'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        var _identification$split = identification.split('#'),
            _identification$split2 = _slicedToArray(_identification$split, 2),
            userName = _identification$split2[0],
            configAlias = _identification$split2[1];

        this.authenticateWith(configAlias, userName, password);
      }
    }
  });

  _exports.default = _default;
});