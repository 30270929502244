define("bottomline-execution/adapters/be/picture", ["exports", "bottomline-execution/adapters/be/base-adapter", "@ember/service", "bottomline-execution/utils/data-uri-to-blob-util", "bottomline-execution/utils/form-data-util", "bottomline-execution/utils/file-to-b64-util", "rsvp", "@ember/runloop"], function (_exports, _baseAdapter, _service, _dataUriToBlobUtil, _formDataUtil, _fileToB64Util, _rsvp, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdapter.default.extend({
    formMultipart: (0, _service.inject)(),
    pictureSaver: (0, _service.inject)(),
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var pictureData = {};
      var serializer = store.serializerFor('pictures');
      serializer.serializeIntoHash(pictureData, type, snapshot);

      var pictureDataForm = function pictureDataForm(pictureData, pictureImageData) {
        return (0, _formDataUtil.formDataBuilder)({
          picture: Object.assign({}, pictureData, {
            image_data: (0, _dataUriToBlobUtil.dataURItoBlobUtil)("data:image/jpeg;base64,".concat(pictureImageData))
          })
        });
      };

      var request = function request(pictureDataForm) {
        return new _rsvp.default.Promise(function (resolve, reject) {
          var imageSize = pictureDataForm.get('picture[image_data]').size;

          if (imageSize <= 0) {
            return reject('Picture file missing data');
          }

          return _this.get('formMultipart').request('/pictures/', {
            processData: false,
            contentType: false,
            method: 'POST',
            data: pictureDataForm
          }).then(function (data) {
            var picture = snapshot.record;
            var tripStop = store.peekRecord(picture.get('TableName'), picture.get('TableKey'));
            tripStop.set('hasUnloadedPictures', false);
            (0, _runloop.run)(null, resolve, data);
          }).catch(function (data) {
            (0, _runloop.run)(null, reject, data);
          });
        });
      };

      if (window.cordova) {
        return this.get('pictureSaver').findPictureFileByGuid(snapshot.record.get('BE_PictureGUID')).then(function (entry) {
          return (0, _fileToB64Util.fileToB64Util)(entry.nativeURL).then(function (base64String) {
            return request(pictureDataForm(pictureData.picture, base64String.split('base64,')[1]));
          });
        });
      } else {
        return request(pictureDataForm(pictureData.picture, pictureData.picture.image_data));
      }
    }
  });

  _exports.default = _default;
});