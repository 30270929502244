define("bottomline-execution/models/truck-position", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    ownerCode: attr('string'),
    truckId: attr('string'),
    haulierId: attr('string'),
    longitude: attr('number'),
    latitude: attr('number'),
    speed: attr('number'),
    changeDt: attr('date')
  });

  _exports.default = _default;
});