define("bottomline-execution/app", ["exports", "@ember/object", "@ember/array", "@ember/application", "ember-data", "bottomline-execution/resolver", "ember-load-initializers", "bottomline-execution/config/environment", "@sentry/browser", "@sentry/integrations", "bottomline-execution/utils/guid-util"], function (_exports, _object, _array, _application, _emberData, _resolver, _emberLoadInitializers, _environment, Sentry, _integrations, _guidUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Sentry.init({
    dsn: _environment.default.SENTRY_DSN,
    integrations: [new _integrations.Debug()],
    release: '00.00.00',
    // This is replaced in the CircleCI build process
    beforeSend: function beforeSend(event, hint) {
      var breadcrumbs = event.breadcrumbs || [];
      var serverErrorPresent = breadcrumbs.get('lastObject.data.status_code') === 500;
      var requestAborted = hint.originalException.message === 'The adapter operation was aborted';
      var rejectError = _environment.default.environment === 'development' || !window.cordova || serverErrorPresent || requestAborted;

      if (rejectError) {
        console.log('%cError report for Sentry intercepted', 'color:red');
        Sentry.configureScope(function (scope) {
          return scope.clearBreadcrumbs();
        });
        return null;
      }

      return event;
    }
  });

  var App = _application.default.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  _emberData.default.Snapshot.prototype.adapterAction = function () {
    return (this.adapterOptions || {}).action;
  };

  _emberData.default.Model.reopen({
    rollbackAttribute: function rollbackAttribute(attributeName) {
      var originalValue = this.changedAttributes(attributeName)[0];
      this.set(attributeName, originalValue);
    },
    groupBy: function groupBy(list, group, totals) {
      var groupedResult = (0, _array.A)([]);
      var groups = (0, _array.A)((0, _array.A)(list).getEach(group)).uniq();
      groups.forEach(function (item) {
        var currentGroup = new _object.default();
        var itemsForGroup = list.filterBy(group, item);
        currentGroup.set('items', itemsForGroup);
        currentGroup.set('group', item);
        totals.forEach(function (total) {
          var totalVal = itemsForGroup.reduce(function (prev, item) {
            if (isNaN(prev)) {
              prev = 0;
            }

            return parseInt(prev, 10) + parseInt(item.get(total) || 0, 10);
          }, 0);
          currentGroup.set('total' + total, totalVal);
        });

        if (itemsForGroup.length > 0) {
          groupedResult.push(currentGroup);
        }
      });
      return groupedResult;
    },
    createGuid: function createGuid() {
      return (0, _guidUtil.guidUtil)();
    }
  });

  var _default = App;
  _exports.default = _default;
});