define("bottomline-execution/controllers/application", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/controller", "@ember/object", "@ember/service", "@ember/object/evented", "@ember/utils", "bottomline-execution/utils/url-utils", "bottomline-execution/mixins/version"], function (_exports, _computed, _runloop, _controller, _object, _service, _evented, _utils, _urlUtils, _version) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_version.default, {
    adrReportController: (0, _controller.inject)('shifts.adr-report'),
    queryParams: ['editTruck'],
    session: (0, _service.inject)(),
    locationService: (0, _service.inject)('location'),
    androidLogging: (0, _service.inject)(),
    offlineSyncerService: (0, _service.inject)('offline-syncer'),
    i18n: (0, _service.inject)(),
    androidPermissions: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    applicationReload: (0, _service.inject)(),
    isGeneratingAdrReport: false,
    _setup: (0, _evented.on)('init', function () {
      this.get('applicationReload');
      this.get('locationService').set('store', this.get('store'));
      this.get('offlineSyncerService').set('store', this.get('store'));
      this.get('androidLogging'); // Initialize service

      this.get('internetConnection');
    }),
    currentTruckChanged: (0, _object.observer)('model.currentTruck', function () {
      this.get('locationService').set('truckId', this.get('model').get('currentTruck'));
    }),
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('model'),
    truckSettings: (0, _computed.alias)('appConfiguration.truckSettings'),
    isShiftIndex: (0, _computed.equal)('currentPath', 'shifts.index'),
    withinShift: (0, _object.computed)('currentPath', function () {
      return this.get('currentPath').indexOf('trips') > -1;
    }),
    emptyShiftsIndex: (0, _object.computed)('currentPath', function () {
      if (this.get('currentPath') === 'shifts.index') {
        return (0, _utils.isEmpty)(this.get('store').peekAll('shift'));
      }

      return false;
    }),
    showADRReport: (0, _object.computed)('withinShift', 'emptyShiftsIndex', function () {
      if (this.get('withinShift') || this.get('emptyShiftsIndex')) return true;
      return false;
    }),
    enableADRReport: (0, _object.computed)('emptyShiftsIndex', 'canGenerateADRReport', function () {
      if (this.get('emptyShiftsIndex') || this.get('canGenerateADRReport')) return true;
      return false;
    }),
    blockExit: (0, _object.computed)('currentPath', function () {
      return this.get('currentPath').indexOf('trips') > -1 || this.get('currentPath').indexOf('adr') > -1;
    }),
    canGenerateADRReport: (0, _object.computed)('internetConnection.isBadQuality', 'appState.anyShiftStarted', 'isGeneratingAdrReport', function () {
      return !this.get('internetConnection.isBadQuality') && this.get('appState.anyShiftStarted') && !this.get('isGeneratingAdrReport');
    }),
    backendEnvironment: (0, _object.computed)('appState.alias', function () {
      var url = this.get('appState.domain');
      var alias = this.get('appState.alias');

      var domain = _urlUtils.default.extractDomainName(url);

      if (_urlUtils.default.isIpAddress(domain)) {
        // If the domain is an IP address, show it as-is
        return domain;
      } else {
        if ((0, _utils.isPresent)(alias)) {
          return alias;
        } else {
          return '';
        }
      }
    }),
    truckSelected: (0, _object.computed)('appState.currentTruck', function () {
      return (0, _utils.isPresent)(this.get('appState').getTruck());
    }),
    currentTruck: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('appState').getTruck().get('identifier');
    }),
    doesTrailerExist: (0, _object.computed)('appState.currentTrailer', function () {
      return (0, _utils.isPresent)(this.get('appState').getTrailer());
    }),
    currentTrailer: (0, _object.computed)('appState.currentTrailer', function () {
      return this.get('appState').getTrailer().get('identifier');
    }),
    canOpenShifts: (0, _object.computed)('appState.currentTruck', function () {
      return (0, _utils.isPresent)(this.get('appState.currentTruck'));
    }),
    actions: {
      back: function back() {
        window.history.back();
      },
      navigate: function navigate(path) {
        switch (path) {
          case 'shifts.index':
            this.transitionToRoute('edit-truck');
            break;

          case 'shifts.show.trips.index':
            alert(this.get('i18n').t('back_button_not_supported'));
            break;

          case 'shifts.show.trips.show.trip-stops.show.index':
            alert(this.get('i18n').t('back_button_not_supported'));
            break;

          default:
        }
      },
      adrReport: function adrReport() {
        var _this = this;

        var useDeprecatedSource = this.get('truckSettings.deprecated_source_adr_report');
        var adrReportController = this.get('adrReportController');

        if (useDeprecatedSource && adrReportController.canGenerateADRReport()) {
          if (useDeprecatedSource == 'jsreport') {
            this.set('isGeneratingAdrReport', true);
            adrReportController.generateADRReport().then(function () {
              return _this.set('isGeneratingAdrReport', false);
            });
          } else if (useDeprecatedSource == 'on-screen') {
            // open the page in the app with ADR information
            this.transitionToRoute('shifts.adr-report', this.get('appState.selectedShift'));
          }
        } else {
          var truckId = this.get('appState.currentTruck');

          var flagOpeningAdrReport = function flagOpeningAdrReport(opening) {
            _this.set('isGeneratingAdrReport', opening);
          };

          var errorOpeningDocument = function errorOpeningDocument(_error) {
            _this.get('f7').alert(_this.get('i18n').t('truck.documents.notfound'), '');
          };

          var pdfDocument = this.get('store').createRecord('pdf-document', {
            documentType: 'adrReport',
            modelId: truckId
          });
          pdfDocument.open({
            signalOpening: flagOpeningAdrReport,
            showWarning: errorOpeningDocument
          });
        }
      },
      sync: function sync() {
        this.get('f7').confirm(this.get('i18n').t('sync.warning'), this.get('i18n').t('sync.title'), (0, _runloop.bind)(this, function () {
          window.location.reload();
        }));
      }
    }
  });

  _exports.default = _default;
});