define("bottomline-execution/templates/components/trip-all-remarks", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Cqzrudot",
    "block": "[[[10,1],[14,0,\"trip-all-remarks__trip-info\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"trip\"]]]],[1,\" \"],[1,[33,1,[\"id\"]]],[13],[1,\"\\n\"],[10,1],[14,0,\"trip-all-remarks__trip-info--right-align\"],[12],[1,\"\\n\"],[41,[33,1,[\"PlannedStartDT\"]],[[[1,\"    \"],[1,[28,[35,3],[[33,1,[\"PlannedStartDT\"]],\"L LT\"],null]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"trip-all-remarks__trip-stop--header\"],[12],[1,[30,1,[\"sitename\"]]],[13],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,1,[\"allRemarks\"]]],null]],null],null,[[[1,\"    \"],[10,0],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null]],[1]],null]],[\"tripStop\",\"remark\"],false,[\"editable-t\",\"trip\",\"if\",\"moment-format\",\"each\",\"-track-array\",\"stopsWithRemarks\"]]",
    "moduleName": "bottomline-execution/templates/components/trip-all-remarks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});