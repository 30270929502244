define("bottomline-execution/services/current-truck-inventory", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/utils", "bottomline-execution/utils/guid-util"], function (_exports, _service, _object, _computed, _utils, _guidUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    truckId: (0, _computed.alias)('appState.currentTruck'),
    trailerId: (0, _computed.alias)('appState.currentTrailer'),
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    truck: (0, _object.computed)('truckId', function () {
      return this.store.peekRecord('truck', this.get('truckId'));
    }),
    inventories: (0, _computed.alias)('appState.shift.truck_inventories'),
    allInventoriesForVehicle: function allInventoriesForVehicle(vehicleIds) {
      vehicleIds = [].concat(vehicleIds);
      return this.get('inventories').filter(function (inventory) {
        return vehicleIds.includes(inventory.get('truck_id'));
      });
    },
    availableMaterials: function availableMaterials() {
      var inventoriesWithMaterial = this.get('inventories').filter(function (inventory) {
        return inventory.get('currentQty') > 0;
      });
      return this.materialIds(inventoriesWithMaterial);
    },
    materialIds: function materialIds() {
      var inventories = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('inventories');
      return inventories.getEach('material_id').compact().uniq();
    },
    materialInventories: function materialInventories() {
      var vehicleIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [this.get('truckId'), this.get('trailerId')];
      return this.allInventoriesForVehicle(vehicleIds).filter(function (inventory) {
        return Number(inventory.get('truck_storage_id')) == 0;
      });
    },
    materialInventoryFor: (0, _computed.alias)('findOrCreateMaterialInventoryFor'),
    findOrCreateMaterialInventoryFor: function findOrCreateMaterialInventoryFor(materialId) {
      var materialInventory = this.materialInventories().findBy('material_id', materialId);

      if ((0, _utils.isEmpty)(materialInventory)) {
        materialInventory = this.get('inventories').createRecord({
          id: (0, _guidUtil.guidUtil)(),
          currentQty: this._initialQuantityForMaterialInventory(materialId),
          material_id: materialId,
          truck_id: this.get('truckId'),
          truck_storage_id: 0
        });
      }

      return materialInventory;
    },
    confirm: function confirm() {
      this.get('inventories').invoke('save');
    },
    rollbackAttributes: function rollbackAttributes() {
      this.get('inventories').toArray().invoke('rollbackAttributes');
    }
  });

  _exports.default = _default;
});