define("bottomline-execution/routes/truck-settings", ["exports", "@ember/routing/route", "@ember/service", "@ember/object/computed"], function (_exports, _route, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    ajax: (0, _service.inject)(),
    truckSettingsAjax: (0, _service.inject)(),
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    model: function model() {
      var _this = this;

      return this.get('truckSettingsAjax').loadSettings(this.get('appState.currentTruck')).then(function () {
        return _this.get('appConfiguration.truckSettings');
      });
    }
  });

  _exports.default = _default;
});