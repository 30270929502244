define("bottomline-execution/components/material-calculation", ["exports", "@ember/object/computed", "@ember/array", "ember", "@ember/component", "@ember/object", "@ember/utils", "@ember/service", "bottomline-execution/mixins/group-by"], function (_exports, _computed, _array, _ember, _component, _object, _utils, _service, _groupBy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var htmlSafe = _ember.default.String.htmlSafe;

  var _default = _component.default.extend(_groupBy.default, {
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    currentTruckInventory: (0, _service.inject)(),
    truck: (0, _object.computed)('appState.currentTruck', function () {
      return this.get('appState').getTruck();
    }),
    selectedAndIsDelivery: (0, _object.computed)('selectedTripStop', function () {
      return this.get('selectedTripStop') && this.get('selectedTripStop.isUnload');
    }),
    materialTransferTripStopTruckStorages: (0, _object.computed)('trip', function () {
      return [].concat.apply([], this.get('trip').transferStops().map(function (transferStop) {
        return [].concat.apply([], transferStop.get('trip_stop_truck_storages').toArray().filter(function (tsts) {
          return tsts.get('ActivityCode') === 'M';
        }));
      }));
    }),
    doesMaterialTransferExist: (0, _object.computed)('materialTransferTripStopTruckStorages', function () {
      return !(0, _utils.isEmpty)(this.get('materialTransferTripStopTruckStorages'));
    }),
    calculatedMaterials: (0, _object.computed)('trip.state', 'trip.trip_stops.@each.confirmedQty', 'trip.trip_stops.@each.trip_stop_truck_storages', 'selectedAndIsDelivery', 'materialTransferTripStopTruckStorages', function () {
      var _this = this;

      var trip = this.get('trip');
      var startStopStorages = trip.startInventoryStop().get('trip_stop_truck_storages').toArray();
      var startGroupedByMaterial = this.groupedAndTotalizedMaybeConfirmed(startStopStorages, 'MaterialID', ['confirmedQty'], true);
      var loadStopStorages = (0, _utils.isPresent)(trip.get('loadStop')) ? trip.get('loadStop.trip_stop_truck_storages').toArray() : [];
      var loadGroupedByMaterial = this.groupedAndTotalizedMaybeConfirmed(loadStopStorages, 'MaterialID', ['confirmedQty']);
      var deliveryStopStorages = [].concat.apply([], trip.deliveryStops().map(function (e) {
        return e.get('trip_stop_truck_storages').toArray();
      }));
      var deliveryGroupedByMaterial = this.groupedAndTotalizedMaybeConfirmed(deliveryStopStorages, 'MaterialID', ['confirmedQty']);
      var correctionStopStorages = [].concat.apply([], trip.correctionStops().map(function (e) {
        return e.get('trip_stop_truck_storages').toArray();
      }));
      var correctionGroupedByMaterial = this.groupedAndTotalizedMaybeConfirmed(correctionStopStorages, 'MaterialID', ['confirmedQty']);
      var materialTransferSourceTripStopTruckStoragesByMaterial = this.groupBy(this.get('materialTransferTripStopTruckStorages').filter(function (tsts) {
        return tsts.get('confirmedQty') < 0;
      }), 'MaterialID', ['confirmedQty']);
      var materialTransferTargetTripStopTruckStoragesByMaterial = this.groupBy(this.get('materialTransferTripStopTruckStorages').filter(function (tsts) {
        return tsts.get('confirmedQty') > 0;
      }), 'MaterialID', ['confirmedQty']);
      var materials = trip.materials().reject(function (m) {
        return m === null;
      }).map(function (material) {
        var started = _this.getPlannedOrConfirmedByMaterial(startGroupedByMaterial, material);

        var loaded = _this.getPlannedOrConfirmedByMaterial(loadGroupedByMaterial, material);

        var delivered = _this.getPlannedOrConfirmedByMaterial(deliveryGroupedByMaterial, material);

        var corrected = _this.getPlannedOrConfirmedByMaterial(correctionGroupedByMaterial, material);

        var leftOver = started + loaded + corrected - delivered;
        var materialRecord = trip.store.peekRecord('material', material);

        var result = _object.default.create({
          materialNameShort: materialRecord.get('MaterialNameShort'),
          materialID: material,
          materialStyle: new htmlSafe("color: ".concat(materialRecord.get('foreground_color'), "; ") + "background-color: ".concat(materialRecord.get('background_color'), ";")),
          startsWith: started,
          loaded: loaded,
          delivered: delivered,
          corrected: corrected,
          leftOver: leftOver,
          currentTruckInventory: 0,
          plannedConfirmedForSelected: 0
        });

        var materialTransferSource = materialTransferSourceTripStopTruckStoragesByMaterial.find(function (tsts) {
          return tsts.group == material;
        });
        var materialTransferTarget = materialTransferTargetTripStopTruckStoragesByMaterial.find(function (tsts) {
          return tsts.group == material;
        });

        if (materialTransferSource) {
          result.materialTransferSourceQty = materialTransferSource.totalconfirmedQty;
          result.leftOver += materialTransferSource.totalconfirmedQty;
        }

        if (materialTransferTarget) {
          result.materialTransferTargetQty = materialTransferTarget.totalconfirmedQty;
          result.leftOver += materialTransferTarget.totalconfirmedQty;
        }

        if (result.materialTransferSourceQty && result.materialTransferTargetQty) {
          result.materialTransferQty = result.materialTransferSourceQty + result.materialTransferTargetQty;
        } else {
          result.materialTransferQty = result.materialTransferSourceQty || result.materialTransferTargetQty;
        }

        if (_this.get('selectedAndIsDelivery')) {
          var selectedStopStorages = _this.get('selectedTripStop.trip_stop_truck_storages').toArray().filter(function (item) {
            return item.get('TruckStorageID') !== null;
          });

          var selectedGroupedByMaterial = _this.groupedAndTotalizedMaybeConfirmed(selectedStopStorages, 'MaterialID', ['confirmedQty']);

          var selected = _this.getPlannedOrConfirmedByMaterial(selectedGroupedByMaterial, material);

          result.setProperties({
            currentTruckInventory: _this.get('currentTruckInventory').quantityForMaterial(material),
            plannedOrConfirmedForSelected: selected
          });
        }

        return result;
      });
      return materials.filter(function (item) {
        return item.get('loaded') > 0 || item.get('delivered') > 0 || item.get('startsWith') > 0;
      });
    }),
    getPlannedOrConfirmedByMaterial: function getPlannedOrConfirmedByMaterial(tripStopStorages, material) {
      var total = tripStopStorages.find(function (item) {
        return item.group === material;
      });

      if (total) {
        return total.get('totalconfirmedQty');
      } else {
        return 0;
      }
    },
    groupedAndTotalizedMaybeConfirmed: function groupedAndTotalizedMaybeConfirmed(list, group, totals) {
      var groupedResult = (0, _array.A)([]);
      var groups = (0, _array.A)((0, _array.A)(list).mapBy(group)).uniq();
      groups.forEach(function (item) {
        var currentGroup = new _object.default();
        var itemsForGroup = list.filterBy(group, item);
        currentGroup.set('items', itemsForGroup);
        currentGroup.set('group', item);
        totals.forEach(function (total) {
          var totalVal = itemsForGroup.reduce(function (prev, item) {
            if (isNaN(prev)) {
              prev = 0;
            } // NOTE: these conditions code can be optimized, but lets keep them as is for better explicitly


            if (item.get('trip_stop.isStartInventory') && item.get('TruckStorageID') != null) {
              return parseInt(prev, 10) + parseInt(item.get('confirmedQty') || 0, 10);
            } else if (item.get('trip_stop.isUnload') && item.get('trip_stop.isConfirmed') && item.get('TruckStorageID') != null) {
              return parseInt(prev, 10) + parseInt(item.get('confirmedQty') || 0, 10);
            } else if (item.get('trip_stop.isLoad') && item.get('trip_stop.isConfirmed') && item.get('TruckStorageID') != null) {
              return parseInt(prev, 10) + parseInt(item.get('confirmedQty') || 0, 10);
            } else if (item.get('trip_stop.isUnload') && !item.get('trip_stop.isConfirmed') && item.get('TruckStorageID') != null) {
              return parseInt(prev, 10) + parseInt(item.get('plannedQty') || 0, 10);
            } else if (item.get('trip_stop.isLoad') && !item.get('trip_stop.isConfirmed') && item.get('TruckStorageID') != null) {
              return parseInt(prev, 10) + parseInt(item.get('plannedQty') || 0, 10);
            } else if (item.get('trip_stop.isCorrection') && item.get('TruckStorageID') != null) {
              return parseInt(prev, 10) + parseInt(item.get('confirmedQty') || 0, 10);
            } else {
              return parseInt(prev, 10);
            }
          }, 0);
          currentGroup.set('total' + total, totalVal);
        });

        if (itemsForGroup.length > 0) {
          groupedResult.push(currentGroup);
        }
      });
      return groupedResult;
    }
  });

  _exports.default = _default;
});