define("bottomline-execution/serializers/inventory", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, _id, _requestType) {
      var mappedInventories = payload.map(function (json) {
        return {
          id: json.storage_id,
          type: 'inventory',
          attributes: {
            InventoryDT: json.inventory_dt,
            inventoryQty: json.inventory_qty,
            StorageID: json.storage_id
          }
        };
      });
      return {
        data: mappedInventories
      };
    },
    serialize: function serialize(snapshot, _options) {
      return {
        id: snapshot.id,
        InventoryDT: snapshot.attr('InventoryDT'),
        InventoryQty: snapshot.attr('inventoryQty'),
        StorageID: snapshot.attr('StorageID')
      };
    }
  });

  _exports.default = _default;
});