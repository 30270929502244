define("bottomline-execution/controllers/settings", ["exports", "rsvp", "@ember/array", "@ember/controller", "@ember/service", "@sentry/browser"], function (_exports, _rsvp, _array, _controller, _service, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    availableScanners: (0, _array.A)([]),
    i18n: (0, _service.inject)(),
    session: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    customerConfiguration: (0, _service.inject)(),
    isPotentialXSS: function isPotentialXSS() {
      var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      if (!str) return false; // Regex to catch XSS

      var regExp = new RegExp(/(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/, 'ig');
      var matches = str.match(regExp);
      return matches && matches.length;
    },
    _getDomainForAlias: function _getDomainForAlias(alias) {
      var _this = this;

      return new _rsvp.Promise(function (resolve, reject) {
        _this.get('f7').showPreloader();

        _this.get('customerConfiguration').loadConfiguration(alias).then(function (result) {
          if (result != 'ok') {
            reject(_this.get('i18n').t('configuration_service_error'));
          } else {
            resolve();
          }
        }, function (error) {
          reject(_this.get('i18n').t(error));
        }).finally(function () {
          _this.get('f7').hidePreloader();
        });
      });
    },
    actions: {
      changeSettings: function changeSettings() {
        var _this2 = this;

        if (this.isPotentialXSS(this.get('model').get('scanner_ssid'))) {
          this.get('f7').alert('Incorrect value', this.get('i18n').t('scanner_ssid'));
          return;
        }

        this.set('errorMessage', null);

        this._getDomainForAlias(this.get('model.alias')).then(function (_) {
          Sentry.setTag('alias', _this2.get('model.alias'));

          _this2.get('toast').info("Settings saved");

          _this2.transitionToRoute('dashboard');
        }, function (error) {
          _this2.set('errorMessage', error);
        });
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.get('toast').info("Change settings cancelled");
        this.transitionToRoute('login');
      }
    }
  });

  _exports.default = _default;
});