define("bottomline-execution/services/offline-syncer", ["exports", "@ember/utils", "@ember/service", "@ember/runloop", "ember", "moment", "rsvp"], function (_exports, _utils, _service, _runloop, _ember, _moment, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    ajax: (0, _service.inject)(),
    internetConnection: (0, _service.inject)(),
    session: (0, _service.inject)(),
    pictureSaver: (0, _service.inject)(),
    isRunning: false,
    isServiceFactory: true,
    tripSaveScheduled: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('tripSaveScheduled', false);
      this.startTrackingUnsavedRecords();
    },
    startTrackingUnsavedRecords: function startTrackingUnsavedRecords() {
      var _this = this;

      _runloop.run.later(function () {
        _this.syncAll().then(function () {
          if (!_ember.default.testing) {
            _this.startTrackingUnsavedRecords();
          }
        });
      }, _ember.default.testing ? 1 : 60000);
    },
    syncAll: function syncAll() {
      return _rsvp.default.all([this.syncPictures(), this.syncTruckPositions()]);
    },
    syncPictures: function syncPictures() {
      if (!this.get('session.isAuthenticated') || !this.get('internetConnection.online')) {
        return;
      }

      return this.get('pictureSaver').syncUnsavedPictures();
    },
    syncTruckPositions: function syncTruckPositions() {
      var _this2 = this;

      if (!this.get('session.isAuthenticated') || !this.get('internetConnection.online')) {
        return;
      }

      if ((0, _utils.isPresent)(this.get('store'))) {
        // forcing id, make sure ember sees it as persisted record
        this.get('store').peekAll('truck-position').filterBy('isLoaded', false).setEach('id', (0, _moment.default)().unix());
        var truckPositions = this.get('store').peekAll('truck-position').filterBy('isNew', true);

        _rsvp.default.all(truckPositions.map(function (t) {
          return t.save();
        })).catch(function (_error) {
          console.error('Error saving truck position(s)');
        }).finally(function () {
          var truckPositions = _this2.get('store').peekAll('truck-position').filterBy('isNew', false).toArray();

          truckPositions.invoke('deleteRecord');
          truckPositions.invoke('unloadRecord');
        });
      }
    }
  });

  _exports.default = _default;
});